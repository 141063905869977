.pcg_float_left {
  float: right;
}
.pcg_conflict_diff {
  text-align: left;
  color: red;
  margin-right: auto;
}
.pcg_help_icon {
  margin-right: 5px;
  color: silver;
}
.pcg_hi_column {
  float: left;
  margin-left: -1em;
  margin-top: -0.5em;
}
.form-section i.fa {
  left: 2em;
}
.form-section i.fa.fa-question-circle {
  position: absolute;
}
